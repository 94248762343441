import React, { useState } from "react";
import Result from "./Result";
import UploadFiles from "./UploadFiles";

const Index = ({ changeType }: { changeType: () => void }) => {
    const [result, setResult] = useState<any>();
    return (
        <>
            {result ? (
                <Result setResult={setResult} result={result} />
            ) : (
                <UploadFiles changeType={changeType} setResult={setResult} />
            )}
        </>
    );
};

export default Index;
