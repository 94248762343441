import { ArrowDownward, Close, KeyboardArrowDown, Upload } from "@mui/icons-material";
import {
    Stack,
    Button,
    Modal,
    Typography,
    IconButton,
    OutlinedInput,
    Menu,
    CircularProgress,
    Divider,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Context } from "../../../context/setting";
import { Api } from "../../../apis";
import UploadBoxIcon from "../../../components/Common/svgLogs/UploadBoxIcon";
const XLSX = require("xlsx");

const AddPatient = ({ open, setOpen }: { open: boolean; setOpen: any }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [details, setdetails] = useState<any>({});
    const [metadata, setMetadata] = useState<any>([]);
    const [loader, setLoader] = useState<boolean>(false);

    return (
        <Stack direction={"row"} gap={2} justifyContent={"flex-end"} p={1}>
            <Button
                variant="contained"
                color="primary"
                sx={{ height: "2.5rem" }}
                onClick={() => {
                    setOpen(true);
                }}
            >
                Add Patient
            </Button>
            <Modal open={open}>
                <Stack alignItems={"center"} justifyContent={"center"} sx={{ height: "100%" }}>
                    <Stack
                        sx={{ background: "#fff", p: 2, borderRadius: "10px", gap: 1, height: "70vh", width: "60vw" }}
                    >
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Typography variant="h6" fontWeight={"bold"}>
                                Add Patient
                            </Typography>
                            <Stack direction={"row"} gap={1} alignItems={"center"}>
                                <Stack direction={"row"} gap={1} alignItems={"center"}>
                                    {metadata?.length > 0 && <DropDown metadata={metadata} setMetadata={setMetadata} />}
                                    <Typography component={"label"} color={"rgba(0, 0, 0, 0.54)"}>
                                        Upload Images
                                    </Typography>
                                    <IconButton component={"label"}>
                                        <input
                                            hidden
                                            type="file"
                                            accept="image/*"
                                            multiple
                                            onChange={(e: { target: { files: any } }) =>
                                                setMetadata([...Array?.from(e?.target?.files)])
                                            }
                                        />
                                        <Upload fontSize="small" />
                                    </IconButton>
                                </Stack>
                                {metadata?.length > 0 && <Stack direction={"row"} gap={1}></Stack>}
                                <IconButton
                                    onClick={() => {
                                        setOpen(false);
                                        setdetails({});
                                        setMetadata([]);
                                    }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </Stack>
                        </Stack>
                        {/* <Divider /> */}
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            py={0.5}
                            mx={1}
                            gap={3}
                            justifyContent={"center"}
                            sx={{
                                boxShadow: "-1px 2px 4px 1px rgba(0, 0, 0, 0.1)",
                                borderRadius: "5px",
                                userSelect: "none",
                                cursor: "pointer",
                            }}
                            component={"label"}
                        >
                            <input
                                hidden
                                type="file"
                                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls, .xlsx, .xlsm, .csv"
                                onChange={async (e: { target: { files: any } }) => {
                                    const reader = new FileReader();
                                    reader.onload = () => {
                                        let json: any = {};
                                        const data = reader.result?.toString()?.split("\n");
                                        const data1 = data?.[1]?.split(",");
                                        data?.[0]?.split(",")?.map((item: string, index: number) => {
                                            json[item] = data1?.[index] || "";
                                        });
                                        setdetails(json);
                                    };
                                    reader.onerror = (error) => console.error("File read error:", error);
                                    reader.readAsText(e?.target?.files[0]);
                                }}
                            />
                            <UploadBoxIcon height={25} width={25} />
                            <Typography variant="subtitle2">
                                Upload <span style={{ color: "#007BFF" }}>Excel</span> To Extract Input Fields
                            </Typography>
                        </Stack>
                        {/* <Divider /> */}

                        <Stack p={1} gap={1.5} flex={1}>
                            <Stack
                                direction={"row"}
                                flexWrap={"wrap"}
                                gap={1}
                                sx={{ overflowY: "auto", flex: "1 0 10vh" }}
                            >
                                {[
                                    "Centre",
                                    "Patient_Name",
                                    "Case_Number",
                                    "Age",
                                    "Age_Group",
                                    "Cycle_Name",
                                    "Cycle_Date",
                                    "LH",
                                    "FSH",
                                    "Down Regulation-E2/Day 2 E2",
                                    "Pre trigger - LH",
                                    "Pre trigger - E2",
                                    "Post trigger - Progesterone",
                                    "Post trigger - LH",
                                    "Stimulation Protocol",
                                    "rFSH Dosage",
                                    "HP hMG Dosage",
                                    "Total Gonadotrophin Dose",
                                    "AFC - Right - Comments",
                                    "AFC - Left - Comments",
                                    "Trigger",
                                    "#OOCYTES COLLECTED",
                                    "MII OOCYTEs",
                                    "Sperm Preparation techniques",
                                    "No. of Fertilized Oocytes",
                                    "Total number of blastocyst formed",
                                    "Grade of embryos - DAY 3",
                                    "Grade of embryos - DAY 5",
                                    "Grade of embryos - DAY 6",
                                    "Grade of embryos - DAY 7",
                                    "PGT Type",
                                    "No. of Embryos Frozen",
                                    "Endometrial Thickness on the day of trigger(mm)",
                                    "No of Frozen Embryo  transferred ",
                                    "Grade of Embryo Transferred ",
                                    "No of SACs -Frozen\r",
                                ].map((item) => (
                                    <Stack
                                        direction={"row"}
                                        justifyContent={"space-between"}
                                        gap={2}
                                        sx={{ width: "48%" }}
                                    >
                                        <Typography variant="subtitle1" fontSize={"0.9rem"}>
                                            {item
                                                ?.split("_")
                                                ?.map((str) => str[0]?.toUpperCase() + str?.slice(1))
                                                ?.join(" ")}
                                            :{" "}
                                        </Typography>
                                        <OutlinedInput
                                            placeholder={item}
                                            size="small"
                                            sx={{ minWidth: "50%", height: "2rem", fontSize: "0.9rem" }}
                                            value={details[item]}
                                            onChange={(e: { target: { value: string } }) =>
                                                setdetails((prev: any) => {
                                                    prev[item] = e?.target?.value;
                                                    return prev;
                                                })
                                            }
                                        />
                                    </Stack>
                                ))}
                            </Stack>
                            <Stack alignItems={"center"}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ width: "8rem" }}
                                    onClick={() => {
                                        try {
                                            if (metadata?.length === 0)
                                                return setContext({
                                                    ...contextStore,
                                                    snackState: {
                                                        open: true,
                                                        errorType: "error",
                                                        message: "Please Upload Image",
                                                    },
                                                });
                                            setLoader(true);
                                            const formData = new FormData();
                                            metadata?.forEach((file: any) => {
                                                formData.append("images", file);
                                            });
                                            formData.append("patient_info", JSON.stringify(details));
                                            Api?.analysisIVF(formData)
                                                ?.then((res: any) => {
                                                    if (res?.status === 200) {
                                                        setContext({
                                                            ...contextStore,
                                                            ivf: {
                                                                ...contextStore?.ivf,
                                                                patientList: [
                                                                    ...contextStore?.ivf?.patientList,
                                                                    {
                                                                        id: contextStore?.ivf?.patientList + 1,
                                                                        details,
                                                                        images: metadata,
                                                                        result: res?.data?.result,
                                                                    },
                                                                ],
                                                            },
                                                        });

                                                        setOpen(false);
                                                        setdetails({});
                                                        setMetadata([]);
                                                    } else {
                                                        setContext({
                                                            ...contextStore,
                                                            snackState: {
                                                                open: true,
                                                                errorType: "error",
                                                                message: "Please Turn on the server",
                                                            },
                                                        });
                                                    }
                                                    setLoader(false);
                                                })
                                                ?.catch((err: any) => {
                                                    setLoader(false);
                                                    setContext({
                                                        ...contextStore,
                                                        snackState: {
                                                            open: true,
                                                            errorType: "error",
                                                            message: "Please Turn on the server",
                                                        },
                                                    });
                                                });

                                            // setPatientList((prev: any[]) => [
                                            //     ...prev,
                                            //     {
                                            //         id: prev?.length + 1,
                                            //         details,
                                            //         images: metadata,
                                            //     },
                                            // ]);
                                            // setOpen(false);
                                            // setdetails({});
                                            // setMetadata([]);
                                        } catch (error) {
                                            console.log(error);
                                        }
                                    }}
                                >
                                    {loader ? (
                                        <Stack>
                                            <CircularProgress
                                                style={{ width: "25px", height: "25px", color: "#fff" }}
                                            />
                                        </Stack>
                                    ) : (
                                        "Add"
                                    )}
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Modal>
            {/* <Button variant="contained" color="primary">
        Continue
    </Button> */}
        </Stack>
    );
};

const DropDown = ({ metadata, setMetadata }: { metadata: any[]; setMetadata: any }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Stack
                component={"button"}
                direction={"row"}
                gap={1}
                alignItems={"center"}
                sx={{ cursor: "pointer" }}
                onClick={handleClick}
            >
                <Typography id="basic-button" noWrap width={"8rem"} variant="caption">
                    {metadata[0]?.name}
                </Typography>
                <KeyboardArrowDown sx={{ fontSize: "0.94rem" }} />
            </Stack>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                {metadata.map((item) => (
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} p={0.5} gap={1}>
                        <Typography variant="subtitle2">{item?.name}</Typography>
                        <IconButton
                            onClick={() => setMetadata((prev: any[]) => prev?.filter((i) => i?.name !== item?.name))}
                        >
                            <Close fontSize="small" />
                        </IconButton>
                    </Stack>
                ))}
            </Menu>
        </>
    );
};

export default AddPatient;
