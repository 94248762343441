import { Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import IVFPatient from "./patient/Index";
import Index from "./gardner/Index";
import Prr from "../../components/Common/svgLogs/Prr";

const IVF = () => {
    const [type, setType] = useState<"" | "patient" | "gardner">("");

    return (
        <>
            {type === "patient" && <IVFPatient changeType={() => setType("")} />}
            {type === "gardner" && <Index changeType={() => setType("")} />}
            {type === "" && (
                <Stack alignItems={"center"} justifyContent={"center"} flex={1}>
                    <Stack justifyContent={"space-between"} alignItems={"center"} gap={8}>
                        <Stack alignItems={"flex-end"}>
                            <Prr />
                            <Typography>IVF</Typography>
                        </Stack>
                        {/* <Stack alignItems={"center"}>
                            <Typography variant="subtitle2" color={"#484848"}>
                                Upload images and information for the patient.
                            </Typography>
                        </Stack> */}
                        <Stack
                            spacing={2}
                            sx={{
                                "& button": {
                                    fontSize: "none",
                                },
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setType("patient");
                                }}
                            >
                                Embryo Analysis
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setType("gardner");
                                }}
                            >
                                Check Gardner Score
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </>
    );
};

export default IVF;
