import { Stack, Typography, Button, IconButton, CircularProgress } from "@mui/material";
import React, { useContext, useState } from "react";
import UploadIcon from "../../../components/Common/svgLogs/HR_Analysis/UploadIcon";
import { Api } from "../../../apis";
import { Context } from "../../../context/setting";
import { Close } from "@mui/icons-material";

const UploadFiles = ({ changeType, setResult }: { changeType: () => void; setResult: any }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [file, setFile] = useState<undefined | File>();
    const [loader, setLoader] = useState<boolean>(false);
    return (
        <Stack alignItems={"center"} flex={1} gap={2} py={8}>
            <Stack alignItems={"center"} gap={2}>
                <Typography variant="h6" fontWeight={"bold"}>
                    Gardner Score
                </Typography>
                <Typography variant="subtitle1">Upload Image to Check</Typography>
            </Stack>
            <Stack alignItems={"center"}>
                {file?.name ? (
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{ mt: 9 }}
                        gap={4}
                    >
                        <Typography>{file?.name}</Typography>
                        <IconButton
                            onClick={() => {
                                setFile(undefined);
                            }}
                        >
                            <Close />
                        </IconButton>
                    </Stack>
                ) : (
                    <Stack
                        sx={{
                            border: "1px dashed #007BFF",
                            height: "6rem",
                            width: "20rem",
                            borderRadius: "10px",
                            mt: 9,
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 4,
                        }}
                        direction={"row"}
                        component={"label"}
                    >
                        <input
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={(e: { target: { files: any } }) => setFile(e?.target?.files[0])}
                        />
                        <UploadIcon width={37} />
                        <Typography sx={{ fontSize: "0.9rem" }}>
                            Drag and Drop or <span style={{ color: "#007BFF" }}>Browse</span>
                        </Typography>
                    </Stack>
                )}
            </Stack>
            <Stack gap={2} mt={2} alignItems={"center"}>
                <Button
                    variant="contained"
                    sx={{ px: 5 }}
                    onClick={() => {
                        if (!file?.name)
                            return setContext({
                                ...contextStore,
                                snackState: {
                                    open: true,
                                    errorType: "error",
                                    message: "Please Upload A Image",
                                },
                            });
                        setLoader(true);
                        const formData = new FormData();
                        formData.append("file", file);
                        Api?.gardnerIVF(formData)
                            ?.then((res: any) => {
                                if (res?.status === 200) {
                                    setResult({ data: res?.data, file });
                                } else {
                                    setLoader(false);
                                    setContext({
                                        ...contextStore,
                                        snackState: {
                                            open: true,
                                            errorType: "error",
                                            message: "Please Turn on the server",
                                        },
                                    });
                                }
                                setLoader(false);
                            })
                            ?.catch((err: any) => {
                                setLoader(false);
                                setContext({
                                    ...contextStore,
                                    snackState: {
                                        open: true,
                                        errorType: "error",
                                        message: "Please Turn on the server",
                                    },
                                });
                            });
                    }}
                >
                    {loader ? (
                        <Stack>
                            <CircularProgress style={{ width: "18px", height: "18px", color: "#fff" }} />
                        </Stack>
                    ) : (
                        "Continue"
                    )}
                </Button>
                <Button sx={{ width: "fit-content", mt: 4 }} onClick={changeType}>
                    Go Back
                </Button>
            </Stack>
        </Stack>
    );
};

export default UploadFiles;
