import { ArrowBackIos, ArrowForwardIos, Close } from "@mui/icons-material";
import { Stack, Typography, IconButton, Button, Table, TableHead, TableCell, TableBody } from "@mui/material";
import moment from "moment";
import React from "react";
import Prr from "../../../components/Common/svgLogs/Prr";

const Result = ({ setResult, result }: { setResult: any; result: any }) => {
    return (
        <Stack flex={1}>
            <Stack alignItems={"center"} justifyContent={"space-between"} direction={"row"}>
                <Button sx={{ width: "fit-content", px: 2 }} onClick={() => setResult(undefined)}>
                    Go Back
                </Button>

                <Prr />
            </Stack>
            <Stack direction={"row"}>
                <Stack
                    direction={"row"}
                    gap={1}
                    p={1}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    flex={"4.2 1 10vw"}
                >
                    <Typography px={1} color={"#007BFF"}>
                        {result?.file?.name?.split(".")[0]}
                    </Typography>
                    <Stack direction={"row"} gap={2} alignItems={"center"}>
                        <Typography color={"#007BFF"} variant="subtitle1">
                            img 1/1
                        </Typography>
                        <Typography color={"#007BFF"} variant="subtitle1">
                            Date : {moment(new Date())?.format("DD-MM-YYYY")}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack flex={"1 1 10vw"}></Stack>
            </Stack>
            <Stack flex={4} direction={"row"}>
                <Stack flex={"4.5 1 10vw"}>
                    <Stack p={1} height={"calc(80vh - 9px)"} justifyContent={"center"} alignItems={"center"}>
                        {result?.file && (
                            <img
                                src={URL?.createObjectURL(result?.file)}
                                alt={result?.file?.name}
                                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                            />
                        )}
                    </Stack>
                    {/* {result?.file && (
                        <Stack flex={1} alignItems={"center"} justifyContent={"center"} direction={"row"} gap={3}>
                            <IconButton disabled={true}>
                                <ArrowBackIos />
                            </IconButton>
                            <IconButton disabled={true}>
                                <ArrowForwardIos />
                            </IconButton>
                        </Stack>
                    )} */}
                </Stack>
                <Stack sx={{ width: "10px", background: "rgba(51, 134, 211, 0.35)" }}></Stack>
                <Stack flex={"1 1 10vw"} gap={1}>
                    <Stack
                        sx={{
                            position: "relative",
                            zIndex: 1,
                            alignItems: "center",
                            "&::before": {
                                content: "''",
                                position: "absolute",
                                top: "50%",
                                left: "0",
                                height: "0.5px",
                                width: "100%",
                                background: "#08080833",
                            },
                            mt: "-9px",
                        }}
                    >
                        <Typography
                            color={"#000"}
                            sx={{
                                background: "#EAEAEA",
                                borderRadius: "10px",
                                width: "fit-content",
                                px: 0.5,
                                fontSize: "0.8rem",
                                zIndex: 2,
                            }}
                        >
                            GARDNER SCORING
                        </Typography>
                    </Stack>
                    <Typography color={"#007BFF"} px={2} pt={2}>
                        GARDNER SCORING
                    </Typography>
                    <Typography color={"#000"} px={3}>
                        {result?.data}
                    </Typography>
                    {result?.data && (
                        <Table>
                            <TableHead>
                                {["EXP", "ICM", "TE"]?.map((head) => (
                                    <TableCell sx={{ textAlign: "center", background: "#e3f4ff" }} size="small">
                                        {head}
                                    </TableCell>
                                ))}
                            </TableHead>
                            <TableBody>
                                <Body data={result?.data} />
                            </TableBody>
                        </Table>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};

const Body = ({ data }: { data: string }) => {
    const result = data?.split("");

    return (
        <>
            {result?.map((res: string, ind: number) => (
                <TableCell sx={{ textAlign: "center", background: ind === 0 ? "inherit" : ICMTE(res) }} size="small">
                    {res}
                    {ind === 0 && EXP(res)}
                </TableCell>
            ))}
        </>
    );
};

const ICMTE = (data: string) => {
    switch (data) {
        case "A":
            return "rgb(222, 255, 199)";
        case "B":
            return "rgb(255, 253, 199)";
        case "C":
            return "rgb(252, 213, 197)";

        default:
            return "rgb(255, 253, 199)";
    }
};

const EXP = (data: string) => {
    switch (data) {
        case "1":
            return " (Early Blastocyst)";
        case "2":
            return " (Blastocyst)";
        case "3":
            return " (Full Blastocyst)";
        case "4":
            return " (Expanded Blastocyst)";
        case "5":
            return " (Hatching Blastocyst)";
        case "6":
            return " (Hatched Blastocyst)";

        default:
            return " (Early Blastocyst)";
    }
};

export default Result;
