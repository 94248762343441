import React from "react";

const Prr = () => {
    return (
        <svg width="101" viewBox="0 0 417 238" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="287.5" cy="194.5" r="12.5" fill="#5BB9F1" />
            <path
                d="M150 148V202H130V64H185.5C229 68.5 238 129 195 144.5L223.5 202H200L174 148H150Z"
                fill="#5BB9F1"
                stroke="#5BB9F1"
            />
            <path
                d="M350 158C345.583 158 341.812 159.562 338.688 162.688C335.562 165.812 334 169.583 334 174C334 178.417 335.562 182.188 338.688 185.312C341.812 188.438 345.583 190 350 190C354.417 190 358.188 188.438 361.312 185.312C364.438 182.188 366 178.417 366 174C366 169.583 364.438 165.812 361.312 162.688C358.188 159.562 354.417 158 350 158ZM366 201.688C364.792 202.396 363.542 203.021 362.25 203.562C358.333 205.188 354.25 206 350 206C345.75 206 341.667 205.188 337.75 203.562C333.833 201.938 330.375 199.625 327.375 196.625C324.375 193.625 322.062 190.167 320.438 186.25C318.812 182.333 318 178.25 318 174C318 169.75 318.812 165.667 320.438 161.75C322.062 157.833 324.375 154.375 327.375 151.375C330.375 148.375 333.833 146.062 337.75 144.438C341.667 142.812 345.75 142 350 142C354.25 142 358.333 142.812 362.25 144.438C366.167 146.062 369.625 148.375 372.625 151.375C375.625 154.375 377.938 157.833 379.562 161.75C381.188 165.667 382 169.75 382 174V206H366V201.688ZM410.475 206H394.475V142H410.475V206ZM402.475 134C399.725 134 397.371 133.021 395.412 131.062C393.454 129.104 392.475 126.75 392.475 124C392.475 121.25 393.454 118.896 395.412 116.938C397.371 114.979 399.725 114 402.475 114C405.225 114 407.579 114.979 409.537 116.938C411.496 118.896 412.475 121.25 412.475 124C412.475 126.75 411.496 129.104 409.537 131.062C407.579 133.021 405.225 134 402.475 134Z"
                fill="#1662A7"
            />
            <path d="M150 128.499V81.9992H170C212.5 80.0012 213 130.502 170 128.499H150Z" fill="white" />
            <path
                d="M14.91 208V61H64.68C75.88 61 85.12 62.96 92.4 66.88C99.68 70.66 105.07 75.91 108.57 82.63C112.07 89.35 113.82 97.05 113.82 105.73C113.82 114.41 112.07 122.11 108.57 128.83C105.07 135.55 99.68 140.87 92.4 144.79C85.12 148.57 75.88 150.46 64.68 150.46H37.17V208H14.91ZM37.17 130.51H63.63C73.15 130.51 80.08 128.27 84.42 123.79C88.9 119.31 91.14 113.29 91.14 105.73C91.14 98.31 88.9 92.36 84.42 87.88C80.08 83.26 73.15 80.95 63.63 80.95H37.17V130.51Z"
                fill="#1662A7"
            />
            <path
                d="M213.9 152.165C213.9 152.165 215.003 149.264 221.335 144.575C227.667 139.886 230.175 139.731 232.487 144.575C234.799 149.42 231 158.995 231 158.995"
                stroke="#5BB9F1"
                stroke-width="3.09417"
                stroke-linecap="round"
            />
            <path
                d="M214.752 226C210.057 226 206.758 225.093 204.855 223.28C202.952 221.466 202 219.135 202 216.285C202 212.529 213.293 181.767 215.704 177.234C218.115 172.571 220.399 168.685 222.556 165.576C224.713 162.597 227.123 159.813 229.788 157.222C232.58 154.502 235.561 152.171 238.733 150.228C240.764 148.933 243.492 147.573 246.918 146.148C250.47 144.594 253.452 143.816 255.863 143.816C258.781 143.816 260.812 144.529 261.954 145.954C263.095 147.249 263.666 148.868 263.666 150.811C263.666 152.365 263.413 153.984 262.905 155.668C262.398 157.222 261.827 158.712 261.192 160.136C260.812 160.914 260.241 161.496 259.479 161.885C258.718 162.144 258.337 162.015 258.337 161.496C258.337 160.849 258.401 160.266 258.528 159.748C258.654 159.23 258.718 158.647 258.718 157.999C258.718 155.538 258.147 153.142 257.005 150.811C255.99 148.35 254.404 147.119 252.247 147.119C249.963 147.119 247.489 147.961 244.824 149.645C242.159 151.199 240.32 152.559 239.304 153.725C236.005 157.222 232.77 161.561 229.598 166.742C226.552 171.923 214.244 203.462 212.849 208.125C211.96 210.845 211.516 213.177 211.516 215.12C211.516 217.321 212.087 218.876 213.229 219.782C214.371 220.689 215.767 221.142 217.417 221.142C218.685 221.142 220.018 220.948 221.413 220.56C222.936 220.171 224.522 219.718 226.172 219.2C229.978 217.904 233.785 215.897 237.591 213.177C241.525 210.327 245.078 207.348 248.25 204.24C251.422 201.001 253.833 198.217 255.482 195.885C255.736 196.144 255.863 196.533 255.863 197.051C255.863 197.699 255.609 198.476 255.101 199.382C254.594 200.16 254.213 200.807 253.96 201.325C251.295 204.952 247.806 208.708 243.491 212.594C239.177 216.35 234.483 219.523 229.407 222.114C224.459 224.704 219.574 226 214.752 226Z"
                fill="#5BB9F1"
            />
        </svg>
    );
};

export default Prr;
