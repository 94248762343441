import { Close, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionSummary, Stack, Typography, Button, AccordionDetails } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PreviewModal from "./PreviewModal";

const Patient = ({ item, lastElement }: { item: any; lastElement: boolean }) => {
    const scrollref = useRef<HTMLDivElement>(null);
    const [expand, setExpand] = useState<boolean>(false);
    const [preview, setPreview] = useState<{ toggle: boolean; data: any }>({ toggle: false, data: {} });
    useEffect(() => {
        if (lastElement) {
            setExpand(lastElement);
            setTimeout(() => {
                scrollref?.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "end",
                });
            }, 300);
        }
    }, [lastElement]);

    return (
        <Stack>
            <Accordion
                sx={{
                    "& .MuiAccordionSummary-root.Mui-expanded": { minHeight: "2.5rem", my: "8px" },
                    "&.Mui-expanded": { my: "8px" },
                    borderRadius: "4px",
                    boxShadow: "none",
                    border: "1px solid #e0e0e0",
                    "&:before": { content: '""', backgroundColor: "transparent" },
                }}
                expanded={expand}
                onChange={() => setExpand(!expand)}
            >
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{ "& .MuiAccordionSummary-content.Mui-expanded": { m: 0 } }}
                >
                    <Stack flex={1} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography sx={{ fontSize: "1.05rem" }}>
                            Patient Name: {item?.details?.Patient_Name}
                        </Typography>
                        <Stack direction={"row"} gap={1} alignContent={"center"} sx={{ mr: 1 }}>
                            <Button
                                sx={{ height: "2rem" }}
                                variant="outlined"
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    setPreview({ toggle: true, data: item });
                                }}
                            >
                                View
                            </Button>

                            {/* <IconButton
                                sx={{ border: "1px solid #e0e0e0", height: "2rem", width: "2rem" }}
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    setPatientList(
                                        (prev: any[]) => prev?.length > 1 && prev?.filter((i) => i?.id !== item?.id)
                                    );
                                }}
                            >
                                <Close />
                            </IconButton> */}
                        </Stack>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
                        {Object.keys(item?.details)?.map((title: string) => (
                            <Stack
                                width={"fit-content"}
                                gap={1}
                                direction={"row"}
                                sx={{ border: "1px solid gray", px: 0.5 }}
                            >
                                <Typography sx={{ fontSize: "0.90rem", fontWeight: "bold" }} variant="caption">
                                    {title
                                        ?.split("_")
                                        ?.map((str) => str[0]?.toUpperCase() + str?.slice(1))
                                        ?.join(" ")}
                                </Typography>{" "}
                                : <Typography sx={{ fontSize: "0.90rem" }}>{item?.details[title]}</Typography>
                            </Stack>
                        ))}
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <div ref={scrollref}></div>
            {preview?.toggle && <PreviewModal preview={preview} setPreview={setPreview} />}
        </Stack>
    );
};

export default Patient;
