import { ArrowBackIos, ArrowForwardIos, Close } from "@mui/icons-material";
import { Drawer, Icon, IconButton, Stack, Table, TableCell, TableRow, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";

const PreviewModal = ({ preview, setPreview }: { preview: { toggle: boolean; data: any }; setPreview: any }) => {
    const [currentImg, setCurrentImg] = useState<number>(1);
    const [details, setDetails] = useState<any>({});

    useEffect(() => {
        if (preview?.data) {
            setDetails(preview?.data);
        }
    }, [preview?.data]);

    return (
        <Drawer
            anchor={"right"}
            open={preview?.toggle}
            variant={"temporary"}
            onClose={() => setPreview({ toggle: false, data: {} })}
        >
            <Stack sx={{ width: "94vw", position: "relative" }} flex={1} direction={"row"}>
                <Stack flex={"4 1 10vw"}>
                    <Stack direction={"row"} gap={1} p={1} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography color={"#007BFF"}>Patient Name : {details?.details?.Patient_Name}</Typography>
                        <Stack direction={"row"} gap={2} alignItems={"center"}>
                            <Typography color={"#007BFF"} variant="subtitle1">
                                img {currentImg}/{details?.images?.length}
                            </Typography>
                            <Typography color={"#007BFF"} variant="subtitle1">
                                Date : {details?.details?.Date || moment(new Date())?.format("DD-MM-YYYY")}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack p={1} sx={{ height: "85vh" }} justifyContent={"center"} alignItems={"center"}>
                        {details?.images && (
                            <img
                                src={URL?.createObjectURL(details?.images[currentImg - 1])}
                                alt={details?.images[currentImg - 1]?.name}
                                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                            />
                        )}
                    </Stack>
                    {details?.images && (
                        <Stack flex={1} alignItems={"center"} justifyContent={"center"} direction={"row"} gap={3}>
                            <IconButton
                                disabled={currentImg === 1}
                                onClick={() => {
                                    if (currentImg > 1) {
                                        setCurrentImg((prev: number) => prev - 1);
                                    }
                                }}
                            >
                                <ArrowBackIos />
                            </IconButton>
                            <IconButton
                                disabled={currentImg === details?.images?.length}
                                onClick={() => {
                                    if (currentImg < details?.images?.length) {
                                        setCurrentImg((prev: number) => prev + 1);
                                    }
                                }}
                            >
                                <ArrowForwardIos />
                            </IconButton>
                        </Stack>
                    )}
                </Stack>
                <Stack sx={{ width: "10px", background: "rgba(51, 134, 211, 0.35)" }}></Stack>
                <Stack flex={"1 1 10vw"} p={2} gap={1}>
                    <Typography color={"#007BFF"}>Patient Details</Typography>
                    <Stack sx={{ flex: "1 0 10vh", overflowY: "auto" }}>
                        <Table size="small">
                            {details?.details &&
                                Object?.keys(details?.details)?.map((info) => (
                                    <TableRow key={info}>
                                        <TableCell
                                            sx={{ border: "1px solid rgba(224, 224, 224, 1)", p: 1 }}
                                            size="small"
                                        >
                                            <Typography variant="caption">
                                                {info
                                                    ?.split("_")
                                                    ?.map((str) => str[0]?.toUpperCase() + str?.slice(1))
                                                    ?.join(" ")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            sx={{ border: "1px solid rgba(224, 224, 224, 1)", p: 1 }}
                                            size="small"
                                        >
                                            <Typography variant="caption" sx={{ wordBreak: "break-all" }}>
                                                {details?.details[info]}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </Table>
                    </Stack>
                    <Stack>
                        <Typography color={"#007BFF"}>Result:</Typography>
                        <Table>
                            {details?.result &&
                                Object?.keys(details?.result)?.length > 0 &&
                                Object?.keys(details?.result)?.map((key) => (
                                    <TableRow>
                                        <TableCell sx={{ border: "1px solid rgba(224, 224, 224, 1)", p: 1 }}>
                                            {key}
                                        </TableCell>
                                        <TableCell sx={{ border: "1px solid rgba(224, 224, 224, 1)", p: 1 }}>
                                            {details?.result[key]}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </Table>
                    </Stack>
                </Stack>
                <IconButton
                    sx={{ position: "absolute", right: 10 }}
                    onClick={() => setPreview((prev: any) => ({ ...prev, toggle: false }))}
                >
                    <Close />
                </IconButton>
            </Stack>
        </Drawer>
    );
};

export default PreviewModal;
