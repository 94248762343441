import { RemoveRedEye } from "@mui/icons-material";
import React from "react";

const IVF = () => {
    return (
        <div style={{ width: "1.063rem", height: "1.125rem" }}>
            <RemoveRedEye fontSize="small" />
        </div>
    );
};

export default IVF;
