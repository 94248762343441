import { Button, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../context/setting";
import Patient from "./Patient";
import AddPatient from "./AddPatient";
import { ArrowBackIos } from "@mui/icons-material";

const IVFPatient = ({ changeType }: { changeType: () => void }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [patientList, setPatientList] = useState<any[]>([]);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        setPatientList(contextStore?.ivf?.patientList || []);
    }, [contextStore?.ivf?.patientList]);

    return (
        <Stack p={1} flex={1} gap={1}>
            <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
                <Button startIcon={<ArrowBackIos sx={{ fontSize: "12px !important" }} />} onClick={changeType}>
                    Back
                </Button>
                <Stack alignItems={"center"}>
                    <Typography variant="h6" fontWeight={"bold"}>
                        IVF
                    </Typography>
                    <Typography variant="subtitle1">Upload images and information for the patient.</Typography>
                </Stack>
                <AddPatient open={open} setOpen={setOpen} />
            </Stack>

            <Stack flex={"1 0 10vh"} gap={1} sx={{ overflowY: "auto", pr: 1, pb: 0.5 }}>
                {patientList.map((item: any) => (
                    <Patient item={item} lastElement={patientList?.length === item?.id && item?.id !== 1} />
                ))}
                {patientList?.length === 0 && (
                    <Stack alignItems={"center"} flex={1} mt={10} gap={2}>
                        <Typography variant="subtitle1">No Patient added yet!</Typography>
                        {/* <Typography variant="subtitle1">Please Add A Patient</Typography> */}
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};

export default IVFPatient;
